import AGB from "./components/AGB";
import Callback from "./components/auth/callback";
import Delivery from "./components/delivery/delivery";
import Information from "./components/information/information";
import DeliveryShoppingCart from "./components/delivery/deliveryCart";
import { Register } from "./components/home/register";
import Impressum from "./components/Impressum";
import Terminal from "./components/terminal/terminal";

export const routingItems = [
	//{ id: "shoppingcart", item: ShoppingCart, path: "/shoppingcart" },
	// { id: "reservation", item: Reservation, path: "/reservation" },
	// { id: "products", item: Products, path: "/products" },
	{ id: "cart", item: DeliveryShoppingCart, path: "/cart" },
	{ id: "register", item: Register, path: "/register" },
	{ id: "terminal", item: Terminal, path: "/terminal" },
	{ id: "impress", item: Impressum, path: "/impress" },
	{ id: "agb", item: AGB, path: "/agb" },
	{ id: "callback", item: Callback, path: "/callback" },
	{ id: "shop", item: Delivery, path: "/shop" },
	{ id: "information", item: Information, path: "/information" },
];

export const headerLinks = [
	// { id: "concept", name: "Konzept", path: "/#concept" },
	// { id: "products", name: "Produkte", path: "/products" },
	// { id: "register", name: "Registrieren", path: "/register" },
	{ id: "shop", name: "Shop", path: "/shop" },
	{ id: "information", name: "Über uns", path: "/information" },
	// { id: "reservation", name: "Reservierung", path: "/reservation" },
	// { id: "team", name: "Team", path: "/#team" },
];

export const footerLinks = {
	// id should match with an FontAwesome FAB Icon
	socialMedia: [
		{
			id: "instagram",
			name: "Instagram",
			path: "https://www.instagram.com/regiostore.info/",
		},
		{
			id: "facebook",
			name: "Facebook",
			path: "https://www.facebook.com/regiostore.info/",
		},
		{
			id: "youtube",
			name: "Youtube",
			path: "https://www.youtube.com/channel/UC6YPIpaduN4hh4y72FoLZQg",
		},
	],
	intern: [
		{ id: "impress", name: "Impressum & Datenschutz", path: "/impress#top" },
	],
};
