/* eslint-disable react-hooks/exhaustive-deps */
import {
	Backdrop,
	Box,
	CircularProgress,
	Fab,
	Grid,
	makeStyles,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import HelpIcon from "@material-ui/icons/Help";
import React, { useCallback, useEffect, useState } from "react";
import { LOG_LEVEL } from "../../enums/log";
import { FILTER, PRICE_UNIT, TAXES } from "../../enums/product";
import { theme } from "../../theme";
import ProductList from "../products/productList";
import ProductScanner from "../products/productScanner";
import FeedbackScreen from "../utilities/feedbackScreen";
import Logger from "../utilities/logger";
import BarcodeDialog from "./barcodeDialog";
import Checkout from "./checkout";
import CouponDialog from "./couponDialog";
import CrateDialog from "./crateDialog";
import HelpDialog from "./helpDialog";
import InactiveDialog from "./inactivityDialog";
import ScaleDialog from "./scaleDialog";
import TerminalStart from "./start";

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: "#eee",
	},
	alert: {
		paddingBlock: 15,
		paddingInline: 25,
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: "#fff",
	},
	fabButtonContainer: (props) => ({
		display: "flex",
		flexDirection: "column",
		position: "fixed",
		right: props.start ? theme.spacing(6) : "33vw",
		top: props.offset * 16 + (props.start ? theme.spacing(6) : 40),
	}),
	fabButton: {
		margin: theme.spacing(2),
	},
}));

function Terminal(props) {
	const [checkoutItems, setCheckoutItems] = useState([]);
	const [unlabeledProducts, setUnlabeledProducts] = useState([]);
	const [selectedScaleProduct, setSelectedScaleProduct] = useState(null);
	const [selectedCrateProduct, setSelectedCrateProduct] = useState(null);
	const [customer, setCustomer] = useState(null);
	const [coupon, setCoupon] = useState(null);
	const [checkoutError, setCheckoutError] = useState(false);
	const [checkoutFeedbackText, setCheckoutFeedbackText] = useState("");
	const [scanDisabled, setScanDisabled] = useState(false);
	const [loading, setLoading] = useState(false);
	const allowedInactivitySeconds = 30;
	const [inactivitySecondsLeft, setInactivitySecondsLeft] = useState(
		allowedInactivitySeconds
	);
	const [openHelpDialog, setOpenHelpDialog] = useState(false);
	const [openBarcodeDialog, setOpenBarcodeDialog] = useState(false);
	const [openCouponDialog, setOpenCouponDialog] = useState(false);

	const classes = useStyles({
		start: customer == null,
		offset: props.headerHeight,
	});

	const [successSound] = useState(new Audio("sounds/success.wav"));
	const [purchaseSound] = useState(new Audio("sounds/purchase.wav"));
	const [warningSound] = useState(new Audio("sounds/warning.wav"));

	const logger = new Logger({ level: LOG_LEVEL.INFO, target: "terminal" });

	const refreshUnlabeledProducts = useCallback(() => {
		logger.info("refresh unlabeled product list");
		setLoading(true);
		var targetUrl = window._env_.RS_HOST.concat("/api/v1/product/unlabeled");
		fetch(encodeURI(targetUrl), {
			method: "GET",
		})
			.then((response) => {
				if (response.ok) {
					return response.json();
				} else {
					logger.warn(
						"Response not ok in unlabeled product request (/api/v1/product/unlabeled):",
						response
					);
				}
			})
			.then((data) => {
				if (data) {
					setUnlabeledProducts([...data]);
				} else {
					logger.warn(
						"No data in unlabeled product request (/api/v1/product/unlabeled)"
					);
				}
				setLoading(false);
			})
			.catch(function (error) {
				logger.error(
					"Error in unlabeled product request (/api/v1/product/unlabeled):",
					error
				);
				setLoading(false);
			});
	});

	const getCoupon = useCallback((code) => {
		logger.info("get coupon", code);
		var targetUrl = window._env_.RS_HOST.concat(
			"/api/v1/regiostore/order/coupon?rfid=" + customer.rfid + "&code=" + code
		);
		let error = true;
		fetch(encodeURI(targetUrl), {
			method: "GET",
		})
			.then((response) => {
				if (response.ok) {
					error = false;
					return response.json();
				} else {
					error = true;
					return response.text();
				}
			})
			.then((data) => {
				if (!error) {
					setCoupon(data);
				} else {
					logger.warn("Error while validating coupon code", code, ": ", data);
				}
			})
			.catch(function (error) {
				logger.error(
					"Error in validate request for coupon code",
					code,
					"(/api/v1/regiostore/order/coupon):",
					error
				);
			});
	});

	useEffect(() => {
		window.addEventListener("click", resetTimeout);
		return () => window.removeEventListener("click", resetTimeout);
	}, []);

	useEffect(() => {
		refreshUnlabeledProducts();
	}, [customer]);

	useEffect(() => {
		if (
			window._env_.RS_DEFAULT_COUPON &&
			coupon === null &&
			customer !== null
		) {
			getCoupon(window._env_.RS_DEFAULT_COUPON);
		}
	}, [customer, coupon]);

	useEffect(() => {
		if (customer !== null) {
			const timer = setTimeout(function () {
				if (inactivitySecondsLeft > 0) {
					setInactivitySecondsLeft(inactivitySecondsLeft - 1);
				} else {
					logger.warn("Inactivity seconds used up. Show Inactivity Dialog.");
				}
			}, 1000);
			return () => clearTimeout(timer);
		}
	}, [customer, inactivitySecondsLeft]);

	const prepareProductScan = () => {
		logger.debug("prepare product scan");
		setScanDisabled(true);
		resetTimeout();
	};

	const handleProductScan = (product) => {
		logger.debug("handle product scan");
		logger.debug(product);
		closeDialog();
		if (product && product != null && product.product) {
			let quantity = 1;
			if (product.product.individualWeight != null) {
				quantity = product.product.individualWeight;
			}
			addCheckoutItem(product, quantity);
		} else {
			logger.warn("product is empty after product scan");
		}
		setScanDisabled(false);
	};

	const prepareCardScan = () => {
		logger.debug("prepare card scan");
		setLoading(true);
		setScanDisabled(true);
	};

	const handleCardScan = (rfid, foundCustomer) => {
		logger.info("handle card scan for rfid", rfid);
		setCustomer(foundCustomer);
		if (foundCustomer != null && foundCustomer.rfid != null) {
			logger.info(
				"customer with rfid",
				foundCustomer.rfid,
				"is now logged in."
			);
			refreshUnlabeledProducts();
		}
		setLoading(false);
		setTimeout(setScanDisabled(false), 5000);
	};

	const addCheckoutItem = (
		webProduct,
		quantity,
		description = null,
		buyingPricePerUnit = null,
		priceUnit = PRICE_UNIT.PIECE,
		taxes = TAXES.NULL,
		discount = 0,
		crate = null
	) => {
		logger.info(
			"add",
			quantity,
			"of",
			webProduct != null
				? "product " + webProduct.title + " (" + webProduct.product.id + ")"
				: description,
			"to checkout items"
		);
		let productExists = false;
		let product = null;
		let barcode = null;
		let checkoutItem = null;
		if (crate == null && webProduct != null && webProduct.product.crate > 0) {
			logger.info("Product has crate. Show crate dialog.");
			setSelectedCrateProduct(webProduct);
			return;
		}
		if (webProduct != null) {
			product = webProduct.product;
			barcode =
				product.individualBarcode != null
					? product.individualBarcode
					: product.barcode;
			description = webProduct.title;
			priceUnit = product.priceUnit;
			buyingPricePerUnit = product.pricePerUnit;
			taxes = product.taxes;
			discount = product.discount === null ? 0 : product.discount;
			successSound.play();
		}
		if (priceUnit === PRICE_UNIT.PIECE) {
			for (var i = 0; i < checkoutItems.length; i++) {
				if (
					(product === null &&
						checkoutItems[i].product === null &&
						checkoutItems[i].description === description) ||
					(product !== null &&
						checkoutItems[i].product !== null &&
						checkoutItems[i].product.id === product.id &&
						checkoutItems[i].discount === discount)
				) {
					logger.info("product already exist in list. Increase quantity.");
					productExists = true;
					checkoutItems[i].quantity += quantity;
					break;
				}
			}
		}
		if (!productExists) {
			checkoutItem = {
				type: "orderItem",
				product: product,
				barcode: barcode,
				description: description,
				priceUnit: priceUnit,
				taxes: taxes,
				buyingPricePerUnit: buyingPricePerUnit,
				quantity: quantity,
				discount: discount,
			};
		}

		if (checkoutItem != null) {
			checkoutItems.unshift(checkoutItem);
		}
		setCheckoutItems([...checkoutItems]);
		if (product != null && product.deposit > 0) {
			addCheckoutItem(null, quantity, "Pfand " + description, product.deposit);
		}
		if (crate) {
			addCheckoutItem(null, 1, "Kistenpfand " + description, 1.5);
		}

		closeDialog();
	};

	const checkout = (pin) => {
		logger.info("perform checkout");
		if (!loading) {
			setLoading(true);
			var targetUrl = window._env_.RS_HOST.concat("/api/v1/regiostore/order");
			var error = false;
			fetch(encodeURI(targetUrl), {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					rfid: customer.rfid,
					pin: pin,
					couponCode: coupon != null ? coupon.code : null,
					orderItems: checkoutItems.slice().reverse(),
				}),
			})
				.then((response) => {
					if (!response.ok) {
						error = true;
					}
					return response.text();
				})
				.then((text) => {
					setCheckoutError(error);
					setCheckoutFeedbackText(text);
					setLoading(false);
					if (error) {
						logger.error(
							"Error in checkout request (/api/v1/regiostore/order):",
							text
						);
						warningSound.play();
					} else {
						logger.info("successfully performed checkout");
						purchaseSound.play();
					}
				})
				.catch(function (error) {
					logger.error(
						"Error in checkout request (/api/v1/regiostore/order):",
						error
					);
					setCheckoutError(true);
					setCheckoutFeedbackText(
						"Fehler beim Abschließen des Einkaufs: " + error
					);
					setLoading(false);
					warningSound.play();
				});
		}
	};

	const handleUnlabeledProductClick = (product) => {
		logger.debug("handle unlabeled product click");
		logger.debug(product);
		if (product.product.priceUnit === PRICE_UNIT.WEIGHT) {
			logger.debug("Open scale dialog");
			setSelectedScaleProduct(product);
		} else {
			addCheckoutItem(product, 1);
		}
	};

	const changeQuantity = (e, index, target, quantity, depth = 0) => {
		const checkoutItem = checkoutItems[index];
		logger.info(
			"change quantity for checkout item",
			checkoutItem.description,
			"to",
			quantity
		);
		logger.debug("depth", depth);
		if (quantity > 0) {
			checkoutItems[index].quantity = quantity;
		} else {
			logger.info("quantity is 0 - remove checkout item");
			checkoutItems.splice(index, 1);
		}
		setCheckoutItems([...checkoutItems]);
		if (depth <= 0) {
			if (checkoutItem.product != null && checkoutItem.product.deposit > 0) {
				for (var i = 0; i < checkoutItems.length; i++) {
					if (
						checkoutItems[i].product === null &&
						checkoutItems[i].description === "Pfand " + checkoutItem.description
					) {
						changeQuantity(e, i, target, quantity, 1);
					}
				}
			}
			if (checkoutItem.product == null) {
				if (checkoutItem.description.startsWith("Kistenpfand")) {
					const description = checkoutItem.description.substr(
						checkoutItem.description.indexOf(" ") + 1
					);
					let productIndex = null;
					let depositIndex = null;
					for (var j = 0; j < checkoutItems.length; j++) {
						if (
							checkoutItems[j].product === null &&
							checkoutItems[j].description === "Pfand " + description
						) {
							depositIndex = j;
						} else if (
							checkoutItems[j].product !== null &&
							checkoutItems[j].description === description
						) {
							productIndex = j;
						}
					}
					if (productIndex !== null) {
						const crate = checkoutItems[productIndex].product.crate;
						const difference =
							quantity -
							Math.floor(checkoutItems[productIndex].quantity / crate);
						const productQuantity =
							checkoutItems[productIndex].quantity + difference * crate;
						changeQuantity(e, productIndex, target, productQuantity, 1);
						if (depositIndex !== null) {
							const depositQuantity =
								checkoutItems[depositIndex].quantity + difference * crate;
							changeQuantity(depositIndex, depositQuantity, 1);
						}
					}
				} else if (checkoutItem.description.startsWith("Pfand")) {
					const description = checkoutItem.description.substr(
						checkoutItem.description.indexOf(" ") + 1
					);
					for (var k = 0; k < checkoutItems.length; k++) {
						if (
							checkoutItems[k].product !== null &&
							checkoutItems[k].description === description
						) {
							changeQuantity(e, k, target, quantity, 1);
						}
					}
				}
			}
		}
	};

	const reset = () => {
		logger.info("reset terminal");
		setCustomer(null);
		setCoupon(null);
		setCheckoutItems([]);
		setOpenBarcodeDialog(false);
		setOpenHelpDialog(false);
		setSelectedCrateProduct(null);
		setSelectedScaleProduct(null);
		resetTimeout();
	};

	const resetTimeout = () => {
		logger.debug("reset Timeout");
		setInactivitySecondsLeft(allowedInactivitySeconds);
	};

	const openDialog = (type) => {
		logger.debug("open dialog", type);
		if (type === "help") {
			setOpenHelpDialog(true);
		} else if (type === "barcode") {
			setOpenBarcodeDialog(true);
		}
	};

	const closeDialog = () => {
		logger.debug("close dialog");
		setSelectedScaleProduct(null);
		setSelectedCrateProduct(null);
		setOpenBarcodeDialog(false);
		setOpenHelpDialog(false);
		setOpenCouponDialog(false);
	};

	const handleCheckoutFeedbackClose = () => {
		logger.debug("handle checkout feedback close");
		if (!checkoutError) {
			reset();
		}
		setCheckoutFeedbackText("");
	};

	return (
		<Box m={4}>
			{customer == null ? (
				<TerminalStart
					loading={loading}
					onCardScan={prepareCardScan}
					onCardScanAfter={handleCardScan}
				/>
			) : (
				<Grid container spacing={theme.spacing(4)} className={classes.root}>
					<ProductScanner
						scanDisabled={scanDisabled}
						onProductScan={prepareProductScan}
						onProductScanAfter={handleProductScan}
					/>
					<Grid item xs={8}>
						<ProductList
							key={"productList"}
							products={unlabeledProducts}
							filter={FILTER.ALPHABET}
							searchBar={false}
							showStock={false}
							onListItemClick={handleUnlabeledProductClick}
							offset={props.headerHeight}
							fullscreen={true}
							infiniteScroll={false}
						/>
					</Grid>
					<Grid item xs={4}>
						<Checkout
							key={"checkout"}
							customer={customer}
							checkoutItems={checkoutItems}
							coupon={coupon}
							openCouponDialog={() => setOpenCouponDialog(true)}
							removeCoupon={() => setCoupon(null)}
							preventCheckout={loading || checkoutFeedbackText !== ""}
							onChangeQuantity={changeQuantity}
							onCheckout={checkout}
							onCancel={reset}
							offset={props.headerHeight}
						/>
					</Grid>
					{selectedScaleProduct != null ? (
						<ScaleDialog
							product={selectedScaleProduct}
							open={selectedScaleProduct != null}
							handleClose={closeDialog}
							onScaleFinished={addCheckoutItem}
						/>
					) : null}
					{selectedCrateProduct != null ? (
						<CrateDialog
							product={selectedCrateProduct}
							open={selectedCrateProduct != null}
							handleClose={closeDialog}
							onSingle={() =>
								addCheckoutItem(
									selectedCrateProduct,
									1,
									selectedCrateProduct.title,
									selectedCrateProduct.product.buyingPricePerUnit,
									selectedCrateProduct.product.priceUnit,
									selectedCrateProduct.product.taxes,
									selectedCrateProduct.product.rabatt,
									false
								)
							}
							onCrate={() =>
								addCheckoutItem(
									selectedCrateProduct,
									selectedCrateProduct.product.crate,
									selectedCrateProduct.title,
									selectedCrateProduct.product.buyingPricePerUnit,
									selectedCrateProduct.product.priceUnit,
									selectedCrateProduct.product.taxes,
									selectedCrateProduct.product.rabatt,
									true
								)
							}
						/>
					) : null}
					{inactivitySecondsLeft <= 0 ? (
						<InactiveDialog
							open={inactivitySecondsLeft <= 0}
							timeoutTime={allowedInactivitySeconds}
							secondsLeft={60}
							onTimeout={reset}
							onCancel={resetTimeout}
						/>
					) : null}
					<BarcodeDialog
						open={openBarcodeDialog}
						handleClose={closeDialog}
						onManualProductScanAfter={handleProductScan}
					/>
					<CouponDialog
						open={openCouponDialog}
						customerRfid={customer.rfid}
						handleClose={closeDialog}
						onValidated={(coupon) => setCoupon(coupon)}
					/>
				</Grid>
			)}
			<FeedbackScreen
				open={checkoutFeedbackText !== ""}
				success={!checkoutError}
				duration={10}
				text={checkoutFeedbackText}
				onClose={handleCheckoutFeedbackClose}
			/>
			<Backdrop open={loading} className={classes.backdrop}>
				<CircularProgress color="inherit" />
			</Backdrop>
			<HelpDialog open={openHelpDialog} handleClose={closeDialog} />
			<Box className={classes.fabButtonContainer}>
				{customer != null ? (
					<Fab
						color="primary"
						size="medium"
						className={classes.fabButton}
						onClick={() => openDialog("barcode")}
						aria-label="barcode"
					>
						<AddIcon />
					</Fab>
				) : null}
				<Fab
					color="primary"
					size="medium"
					className={classes.fabButton}
					onClick={() => openDialog("help")}
					aria-label="help"
				>
					<HelpIcon />
				</Fab>
			</Box>
		</Box>
	);
}

export default Terminal;
