export const round = (number, decimal = 2) => {
	return Math.round(number * 10 ** decimal) / 10 ** decimal;
};

export const formatNumber = (number, decimal) => {
	return Number(number).toFixed(decimal).toString().replace(".", ",");
};

export const getNumberOfDecimalPlaces = (number) => {
	let text = number.toString();
	// verify if number 0.000005 is represented as "5e-6"
	if (text.indexOf("e-") > -1) {
		let [base, trail] = text.split("e-");
		let deg = parseInt(trail, 10);
		return deg;
	}
	// count decimals for number in representation like "0.123456"
	if (Math.floor(number) !== number) {
		return number.toString().split(".")[1].length || 0;
	}
	return 0;
};

export const isNumber = (value) => {
	return typeof value !== "undefined" && value !== null && !isNaN(value);
};
