/* eslint-disable react-hooks/exhaustive-deps */
import {
	Backdrop,
	Box,
	Button,
	CircularProgress,
	makeStyles,
	Typography,
    Card, 
    CardContent, 
    CardMedia
} from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../App";
import { LOG_LEVEL } from "../../enums/log"
import { useAuth } from "../../provider/authProvider";
import { useHeader } from "../../provider/headerProvider";
import Logger from "../utilities/logger";

const logger = new Logger({ level: LOG_LEVEL.INFO, target: "information" });

const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        flexDirection: "row", /* Standardmäßig nebeneinander */
        flexWrap: "wrap", /* Erlaubt den Elementen, in eine neue Zeile zu wechseln */
        marginBottom: "2rem",
    },
    box: {
        flex: 1, /* Sorgt dafür, dass beide Boxen den verfügbaren Platz ausfüllen */
        alignItems: "center",
    },
    imageContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between", // Fügt einen gleichmäßigen Abstand zwischen den Bildern hinzu
        flexWrap: "wrap",
        paddingBottom: 100,
    },
    imageBox: {
        flexBasis: "30%", // Gibt den Bildern eine Basisgröße, sodass drei Bilder nebeneinander passen
        margin: "10px",
        // Entfernt den flex-grow und flex-shrink Stil, um zu verhindern, dass die Bilder ihre Größe ändern
        textAlign: "center",
    },
    '@media (max-width: 768px)': {
        imageBox: {
            flexBasis: "100%", // Lässt die Bilder auf kleineren Bildschirmen die volle Breite einnehmen
            marginVertical: "20%",
        },
        imageContainer: {
            flexDirection: "column",
            height: "auto",
        },
        container: {
            flexDirection: "column",
            height: "auto",
            
        },
        box: {
            flexBasis: "100%", // Lässt die Bilder auf kleineren Bildschirmen die volle Breite einnehmen
            marginVertical: "20%",
        },
        image: {
            width: "70%",
        }
    },
    '@media (min-width: 769px)': {
        image: {
            maxWidth: "100%",
        }
    },
    titelText: {
        margin: "auto",
        textAlign: "center",
    },
    card: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(2),
      },
      media: {
        height: 300,
        width: 300,
      },
      info: {
        display: 'flex',
        flexDirection: 'column',
      },
}));

function Information(props) {
	const auth = useAuth();
	const header = useHeader();
	const { state, dispatch } = useContext(AppContext);

	const classes = useStyles();

	return (
        <div>
            <div className={classes.container}>
                <Box className={classes.box}>
                    <img src="about/mockup/packstation-doppel.jpg" width={"100%"} alt="Packstation" />
                </Box>
                <Box className={[classes.box, classes.titelText]}>
                    <Typography variant="h4">IHR Supermarkt</Typography>
                    <Typography variant="h4">In IHREM Büro</Typography>
                    <Typography variant="h4">Für IHRE Mitarbeiter</Typography>
                    <Typography variant="h6">Kleiner Aufwand, große Wirkung </Typography>
                    <Typography variant="subtitle1">Ihre Mitarbeiter verdienen mehr als nur einen Arbeitsplatz.</Typography>
                    <Typography variant="subtitle1">Stärken Sie als innovativer Arbeitgeber mit unserer Frischestation das betriebliche Wohlbefinden und unterstützen Sie einen gesundheitsbewussten Lifestyle.</Typography>
                </Box>
            </div>
            <Typography variant="h3" align="center">Der Ablauf</Typography>
            <section className={classes.imageContainer}>
                <Box className={classes.imageBox}>
                    <img className={classes.image} src="about/mockup/3.png" alt="Bild 1" />
                    <Typography variant="h5">1. Registrieren</Typography>
                    <Typography variant="subtitle1">Ihre Mitarbeitenden registrieren sich einfach unter https://regionalwert-frischestation.de</Typography>
                </Box>
                <Box className={classes.imageBox}>
                    <img className={classes.image} src="about/mockup/Shop.png" alt="Bild 1" />
                    <Typography variant="h5">2. Shoppen</Typography>
                    <Typography variant="subtitle1">Danach können die Mitarbeitenden im Shop einkaufen.</Typography>
                </Box>
                <Box className={classes.imageBox}>
                    <img className={classes.image} src="about/mockup/packstation-einzel.png" alt="Bild 1"/>
                    <Typography variant="h5">3. Lieferung</Typography>
                    <Typography variant="subtitle1">Die Lieferung erfolgt dann in die Frischestation</Typography>
                </Box>
            </section>
            <section>
                <div>
                    <Card className={classes.card}>
                        <CardMedia
                            className={classes.media}
                            image="about/mockup/Ansprechpartner.png" // Setze hier den Pfad zu deinem Bild
                            title="Profilbild"
                        />
                        <CardContent className={classes.info}>
                            <Typography variant="h5">Ihr Ansprechpartner</Typography>
                            <Typography variant="subtitle1">Lars Kundt</Typography>
                            <Typography variant="subtitle1">kundt (at) regionalwert-ag.de</Typography>
                            <Typography variant="subtitle1">0761 21 63 30 24</Typography>
                        </CardContent>
                    </Card>
                </div>
            </section>
        </div>
	);
}

export default Information;
