import { createTheme } from "@material-ui/core/styles";

export const theme = createTheme({
	spacing: 2,
	typography: {
		fontFamily: "Ubuntu",
	},
	palette: {
		primary: {
			main: "#ADC523",
			contrastText: "#f2f2f2",
		},
		secondary: {
			main: "#426b37",
			contrastText: "#f2f2f2",
		},
		tertiary: {
			main: "#333333",
			contrastText: "#f2f2f2",
		},
		text: {
			primary: "#333333",
			//secondary: "#333",
		},
		background: {
			main: "#ffffff",
		},
		error: {
			main: "#f44336",
			contrastText: "#f2f2f2",
		},
	},
});
