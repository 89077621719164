import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, makeStyles, Typography, useMediaQuery } from "@material-ui/core";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { useHeader } from "../../provider/headerProvider";
import { ScrollProgressBar } from "../utilities/scrollProgressBar";
import HeaderPlaceholder from "./headerplaceholder";

const useStyles = makeStyles({
	root: {
		margin: "auto",
		width: "100%",
		paddingInline: "1rem",
		display: "flex",
		fontSize: "large",
		overflow: "hidden",
		flexShrink: 0,
		background: "white",
		boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.2)",
		position: "fixed",
		zIndex: 999,
		top: 0,
	},
	container: {
		//width: "100%",
		maxWidth: 1152,
		display: "flex",
		flexGrow: 2,
		margin: "auto",
	},
	logo: {
		maxHeight: "4rem",
		maxWidth: "7rem",
	},
	menuIcon: {
		display: "block",
		position: "fixed",
		top: "1.5rem",
		left: 15,
	},
	iconContainer: {
		display: "flex",
		position: "fixed",
		alignItems: "center",
		right: 0,
		marginInlineEnd: 12,
		top: 0,
		marginBlockStart: 6,
	},
	headerItems: {
		margin: "auto",
		marginLeft: 0,
		display: "flex",
	},
	headerItemsMobile: {
		display: "none",
	},
	headerItemsMobileUnfolded: {
		position: "relative",
		float: "none",
		display: "block",
		textAlign: "left",
		marginTop: "0.5rem",
	},
	headerItem: {
		display: "block",
		marginLeft: 0,
		paddingBottom: "0.5rem",
		paddingTop: "0.5rem",
	},
});

export default function Header(props) {
	const header = useHeader();
	const [unfold, setUnfold] = useState(false);
	const mobile = useMediaQuery("(max-width:600px)");
	const classes = useStyles();
	const padding = 0.5;
	const logoHeight = props.height - 2 * padding;

	return (
		<div>
			<ScrollProgressBar />
			<header
				className={classes.root}
				style={{ paddingBlock: `${padding}rem` }}
			>
				<Box
					className={classes.container}
					style={mobile ? { display: "block" } : {}}
				>
					<Link
						to="#"
						className={classes.menuIcon}
						style={mobile ? {} : { display: "none" }}
					>
						<FontAwesomeIcon
							icon={["fas", "bars"]}
							onClick={() => setUnfold(!unfold)}
						/>
					</Link>
					<HashLink
						key="home"
						style={mobile ? { width: "100vw", paddingLeft: 35 } : {}}
						to="/#top"
						onClick={(e) => setUnfold(false)}
					>
						<img
							alt="Logo"
							className={classes.logo}
							style={{ height: `${logoHeight}rem` }}
							src="/Logo.svg"
						/>
					</HashLink>
					<Box
						className={
							mobile
								? unfold
									? classes.headerItemsMobileUnfolded
									: classes.headerItemsMobile
								: classes.headerItems
						}
					>
						{props.links &&
							!!props.links.length &&
							props.links.map((link) => (
								<HashLink
									key={link.id}
									to={link.path}
									className={mobile ? classes.headerItem : null}
									style={mobile ? {} : { marginLeft: "2.5rem" }}
									onClick={() => setUnfold(false)}
								>
									<Typography variant="h6" color="textPrimary">
										{link.name}
									</Typography>
								</HashLink>
							))}
					</Box>
				</Box>
				<Box className={classes.iconContainer}>
					{header.icons.map((icon, index) => (
						<Box key={"icon" + index}>{icon}</Box>
					))}
				</Box>
			</header>
			<HeaderPlaceholder headerHeight={props.height + 0.25} />
		</div>
	);
}
