import { DISTANCE, PRICE_UNIT, STOCK_TYPE } from "../../enums/product";
import { formatNumber, round } from "../utilities/numberUtils";

export const getPrice = (priceUnit, pricePerUnit) => {
	let price = pricePerUnit;
	if (price < 0) {
		price = 0;
	}
	if (priceUnit === PRICE_UNIT.WEIGHT && price >= 4) {
		price = price / 10;
	}
	return round(price, 3);
};

export const getDisplayPrice = (priceUnit, pricePerUnit) => {
	const price = getPrice(priceUnit, pricePerUnit);
	let metric = "Stück";
	if (priceUnit === PRICE_UNIT.WEIGHT) {
		metric = "kg";
		if (pricePerUnit >= 4) {
			metric = "100 g";
		}
	}
	return formatNumber(price, 2) + " € / " + metric;
};

export const getAmount = (priceUnit, quantity, pricePerUnit, discount) => {
	if (!discount) {
		discount = 0;
	}
	quantity = priceUnit === PRICE_UNIT.WEIGHT ? quantity * 10 : quantity;
	const price = getPrice(priceUnit, pricePerUnit);
	return round(quantity * price * (1 - discount));
};

export const calcDiscountAmount = (amount, discount) => {
	return discount && discount > 0 ? (amount * discount) / 100 : 0;
};

export const calcCouponDiscountAmount = (amount, coupon) => {
	return coupon != null
		? coupon.type === "AMOUNT"
			? coupon.value
			: (amount * coupon.value) / 100
		: 0;
};

export const calcAmount = (items) => {
	let amount = 0;
	items.forEach(
		(item) =>
			(amount += getAmount(
				item.priceUnit,
				item.quantity,
				item.buyingPricePerUnit,
				item.discount
			))
	);
	return amount;
};

export const calcTotalAmount = (items, customer, coupon) => {
	const amount = calcAmount(items);
	const discountAmount = calcDiscountAmount(amount, customer);
	const couponDiscountAmount = calcCouponDiscountAmount(
		amount - discountAmount,
		coupon
	);
	return amount - discountAmount - couponDiscountAmount;
};

export const getStock = (priceUnit, stockType, stock) => {
	if (stock <= 0) {
		return "Aktuell nicht verfügbar";
	}
	let unit = " ";
	if (priceUnit === PRICE_UNIT.WEIGHT && stockType === STOCK_TYPE.WEIGHT) {
		if (stock < 1) {
			unit = " g";
			stock = formatNumber(stock * 1000, 0);
		} else {
			unit = " kg";
		}
	}
	return "Noch " + stock + unit + " verfügbar";
};

export const getLabelList = (product, showRegionalLabels) => {
	let labelList = [];
	if (product) {
		if (product.regionalType && showRegionalLabels) {
			labelList.push(product.regionalType);
		}
		if (
			product.distance &&
			product.distance !== DISTANCE.GREATER100 &&
			showRegionalLabels
		) {
			labelList.push(product.distance);
		}
		if (product.organicCertification) {
			labelList.push(product.organicCertification);
		}
		if (product.fairtradeCertification) {
			labelList.push(product.fairtradeCertification);
		}
	}
	return labelList;
};

export const formatQuantity = (product, quantity, maxDecimal = 2) => {
	if (product.stocktype === STOCK_TYPE.WEIGHT) {
		return round(quantity, maxDecimal);
	}
	return round(quantity, 0);
};

export const getWeightText = (weight) => {
	return formatNumber(weight * 1000, 0) + " g";
};
