import {
	compareCarts,
	getNumberOfItemsInCart,
} from "./components/delivery/deliveryAction";

const reducer = (state, action) => {
	switch (action.type) {
		case "setProducts":
			if (state.products === action.products) {
				return state;
			} else {
				return {
					...state,
					products: action.products,
				};
			}
		case "addToCart":
			let barcode = null;
			let index = -1;
			let newItem = false;
			if (action.barcode && action.barcode !== null) {
				barcode = action.barcode;
				index = state.cart.items
					.map((item) => item.codedBarcode)
					.indexOf(barcode);
				if (action.quantity > 0) {
					newItem = true;
				}
			} else {
				index = state.cart.items
					.map((item) => item.product.id)
					.indexOf(action.product.productId);
				if (index < 0) {
					newItem = true;
				}
			}
			if (!newItem) {
				if (barcode !== null) {
					if (index >= 0) {
						state.cart.items.splice(index, 1);
					}
				} else {
					state.cart.items[index].quantity = action.quantity;
					if (
						action.product.stocktype !== "UNLIMITED" &&
						state.cart.items[index].quantity > action.product.stock
					) {
						state.cart.items[index].quantity = action.product.stock;
					}
					if (state.cart.items[index].quantity < action.product.quantitySteps) {
						state.cart.items.splice(index, 1);
					}
				}
				state.cart.changed = true;
				state.cart.deviceId = state.deviceId;
			} else {
				let stock = action.quantity;
				if (barcode !== null) {
					stock = getNumberOfItemsInCart(state, action.product);
				}
				if (
					stock <= action.product.stock ||
					action.product.stocktype === "UNLIMITED"
				) {
					state.cart.items = [
						...state.cart.items,
						{
							type: "cartItem",
							product: {
								id: action.product.productId,
							},
							codedBarcode: barcode,
							description: action.product.title,
							buyingPricePerUnit: action.product.pricePerUnit,
							priceUnit: action.product.priceUnit,
							taxes: action.product.taxes,
							quantity: action.quantity,
						},
					];
				}
				state.cart.changed = true;
				state.cart.deviceId = state.deviceId;
			}
			return {
				...state,
				cart: state.cart,
			};
		case "setCart":
			let cart = action.cart;
			if (!action.overwrite) {
				if (!compareCarts(state.cart, cart)) {
					cart.items = state.cart.items;
					cart.changed = true;
				}
			}
			return {
				...state,
				cart: cart,
			};
		case "changeCartCouponCode":
			state.cart.coupon = action.coupon;
			state.cart.changed = true;
			return {
				...state,
				cart: state.cart,
			};
		case "changeCartDeliveryDate":
			state.cart.deliveryDate = action.deliveryDate;
			state.cart.changed = true;
			return {
				...state,
				cart: state.cart,
			};
		case "correctCart":
			if (action.itemsToRemove && action.itemsToRemove.length > 0) {
				action.itemsToRemove.forEach((itemToRemove) => {
					let index = -1;
					if (itemToRemove.codedBarcode) {
						index = state.cart.items
							.map((item) => item.codedBarcode)
							.indexOf(itemToRemove.codedBarcode);
					} else {
						index = state.cart.items
							.map((item) => item.product.id)
							.indexOf(itemToRemove.product.id);
					}
					if (index > -1) {
						if (itemToRemove.quantity >= state.cart.items[index].quantity) {
							state.cart.items.splice(index, 1);
						} else {
							state.cart.items[index].quantity =
								state.cart.items[index].quantity - itemToRemove.quantity;
						}
					}
				});
				state.cart.changed = true;
			}
			return {
				...state,
				cart: state.cart,
			};
		case "emptyCart":
			return {
				...state,
				cart: { items: [] },
			};
		case "setReservationProducts":
			if (state.reservationProducts === action.reservationProducts) {
				return state;
			} else {
				return {
					...state,
					reservationProducts: action.reservationProducts,
				};
			}
		case "addToFavorites":
			return {
				...state,
				favorites: state.favorites.concat(action.productId),
			};
		case "removeFromFavorites":
			var favoriteIndex = state.favorites.indexOf(action.productId);
			if (favoriteIndex > -1) {
				state.favorites.splice(favoriteIndex, 1);
			}
			return {
				...state,
				favorites: state.favorites,
			};
		case "setSelectedFetchtime":
			return {
				...state,
				selectedFetchtime: action.selectedFetchtime,
			};
		default:
			return state;
	}
};

export default reducer;
